import api from '@api/api'

export type ThemeConfigFile = {
  colors: {
    primary: string
    secondary: string
    overlay: string
    highlight1: string
    highlight2: string
    link: string
    background: string
    title: string
    paragraph: string
    gradient: {
      default: string
    }
  }
  header: {
    showLogo: boolean
    bg: string
    url: string
  }
  cover: {
    textColor: string
    bg: string
    bgUrl: string
    showSubtitle: boolean
    showHeading: boolean
    overlay: boolean
    text: {
      title: string
      subtitle: string
      heading: string
    }
  }
  fonts: {
    primary: string
    secondary: string
  }
}

interface Theme {
  name: string
  resources: Array<unknown>
  thumbnail: string
  config: ThemeConfigFile
}

/**
 * Get a array of themes
 */
export const getThemes = () => {
  return api.get<Theme[]>('/themes')
}

/**
 * Get a single theme based on id
 * @param {string} id Theme id
 */
export const getThemeById = (id: string) => {
  return api.get<Theme>(`/themes/${id}`)
}

/**
 * Create a theme based on ThemeInterface.
 * @param {Theme} data ThemeInterface
 */
export const createTheme = (data: Theme) => {
  return api.post('/themes', data)
}

/**
 * Apply theme to the entity with the given id. The entity has to be a Unit, Subject, Area or Project.
 * @param {string} themeId Theme id
 * @param {string} entityId Entity id (projec t/area/subject/unit)
 */
export const applyTheme = (themeId: string, entityId: string) => {
  return api.post(`themes/${themeId}/apply/${entityId}`)
}

/**
 * Updates theme based on id
 * @param id Theme id
 * @param {Theme} data Data to update theme
 * @returns {Theme}
 */
export const updateTheme = (id: string, data: Theme) => {
  return api.put(`/themes/${id}`, data)
}

/**
 * Delete a single theme based on id
 * @param {string} id
 */
export const deleteTheme = (id: string) => {
  return api.delete(`themes/${id}`)
}
